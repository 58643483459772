import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavbarSection.module.css";
import Settings from "../settings/Settings";

const NavbarSection = ({
  messagePositive,
  setExpandBankConnectionsAmount,
  expandBankConnectionsAmount,
  requisitionLength,
  amountOfRequisitions,
  isRequisitionLimitReached,
  handleAddAccountClick,
  expandUrls,
  setExpandUrls,
  MayGetExtraUrlForEachAccountNumberForExcel,
  handleExcelInstructionsClick,
  excelInstructions,
  click1,
  click2,
  click3,
  click4,
  click5,
  click7,
  click8,
  click9,
  click10,
  clickBalance4,
  clickBalance5,
  clickBalance6,
  clickBalance7,
  clickBalance8,
  clickBalance9,
  accountIds,
  userId,
  accounts,
  handlePositiveMessageOnClick,
  message,
  showCopyUrlSection,
  showConnectionLimits,
}) => {
  const navigate = useNavigate();

  const [showBalanceInstructions, setShowBalanceInstructions] = useState(false);
  const [showTransactionInstructions, setShowTransactionInstructions] =
    useState(false);
  const [selectedInstructionsBtn, setSelectedInstructionsBtn] = useState(false);

  return (
    <div className={styles.navbarSection}>
      <div className={styles.navbar}>
        <h2 className={styles.header}>Bank Data</h2>
        <Settings />
        <div className={styles.addAnotherAccount}>
          <button
            className={styles.logoutBtn}
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/Login");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 20"
              fill="currentColor"
              className={styles.logoutIcon}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10.09 15.59L8.67 17l-5-5 5-5 1.41 1.41L6.83 11H18v2H6.83l3.26 3.59zM20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H4V5h16v14z" />
            </svg>
            <p>Logout</p>
          </button>
        </div>
      </div>
      {messagePositive && (
        <div className={styles.messagePositive}>
          <p>{messagePositive}</p>
        </div>
      )}

      <div className={styles.headerBox}>
        {showConnectionLimits && (
          <div
            onClick={() =>
              setExpandBankConnectionsAmount(!expandBankConnectionsAmount)
            }
          >
            <p className={styles.connectionsAmount}>Add Account /See Limit</p>
            {expandBankConnectionsAmount && (
              <div className={styles.requisitionContainer}>
                <div className={styles.requisitionLength}>
                  <div className={styles.requisitionInfo}>
                    <p>
                      <strong>Requisition Amount:</strong> {requisitionLength}
                    </p>

                    <p>
                      <strong>Requisition Limit:</strong> {amountOfRequisitions}
                    </p>
                  </div>
                  {!isRequisitionLimitReached && (
                    <div className={styles.addAnotherAccountBtn}>
                      <button onClick={() => navigate("/ConnectToBank")}>
                        Add Another Account
                      </button>
                    </div>
                  )}
                  {isRequisitionLimitReached && (
                    <div className={styles.addAnotherAccountBtn}>
                      <button onClick={handleAddAccountClick}>
                        Add Another Account
                      </button>
                    </div>
                  )}
                  {isRequisitionLimitReached && (
                    <div className={styles.contactSupportMessage}>
                      <p>
                        You have reached the limit. Contact support to increase
                        the limit.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {showCopyUrlSection && (
          <div className={styles.copyUrlBox}>
            <div
              className={styles.copyUrlTitle}
              onClick={() => {
                setSelectedInstructionsBtn(!selectedInstructionsBtn);
                setExpandUrls(false);
                if (showBalanceInstructions || showTransactionInstructions) {
                  setShowBalanceInstructions(false);
                  setShowTransactionInstructions(false);
                }
              }}
            >
              ⬇️ Copy url for Excel Data Import ⬇️
            </div>
            {selectedInstructionsBtn && (
              <div className={styles.selectedInstructionBtnsContainer}>
                <button
                  className={`${styles.selectedInstructionsBtn} ${
                    showTransactionInstructions ? styles.selected : ""
                  }`}
                  onClick={() => {
                    setShowBalanceInstructions(false);
                    setShowTransactionInstructions(true);
                    setExpandUrls(true);
                  }}
                >
                  Transaction Instructions
                </button>

                <button
                  className={`${styles.selectedInstructionsBtn} ${
                    showBalanceInstructions ? styles.selected : ""
                  }`}
                  onClick={() => {
                    setShowTransactionInstructions(false);
                    setShowBalanceInstructions(true);
                    setExpandUrls(true);
                  }}
                >
                  Balance Instructions
                </button>
              </div>
            )}

            {MayGetExtraUrlForEachAccountNumberForExcel && expandUrls && (
              <div className={styles.accountIds}>
                <div className={styles.excelInstructions}>
                  <div className={styles.instructions}>
                    <p>To import the data into Excel, follow these steps:</p>
                    <ol className={styles.instructionsList}>
                      <li>
                        Copy the URL of the account you want to import by
                        clicking on it.
                      </li>
                      <li>
                        <p className={styles.TitleForExplaination}>
                          Left side press is for transactions - Right side is
                          for Balance/s
                        </p>
                        {accountIds.map((id) => (
                          <div key={id} className={styles.accountIdContainer}>
                            {showTransactionInstructions && (
                              <div
                                className={styles.accountId}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}/${
                                      accounts.find(
                                        (account) => account.id === id
                                      ).iban
                                    }`
                                  );
                                  handlePositiveMessageOnClick(
                                    `${
                                      accounts.find(
                                        (account) => account.id === id
                                      ).iban || id
                                    } URL copied to clipboard`
                                  );
                                }}
                              >
                                <span>
                                  Transactions For This Account{" "}
                                  {accounts.find((account) => account.id === id)
                                    .iban || id}
                                </span>
                                <span className={styles.copyIcon}>📋</span>
                              </div>
                            )}
                            {showBalanceInstructions && (
                              <div
                                className={styles.accountId}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/Balance/${userId}/${
                                      accounts.find(
                                        (account) => account.id === id
                                      ).iban
                                    }`
                                  );
                                  handlePositiveMessageOnClick(
                                    `${
                                      accounts.find(
                                        (account) => account.id === id
                                      ).iban || id
                                    } URL copied to clipboard`
                                  );
                                }}
                              >
                                <span>
                                  Balance This Account{" "}
                                  {accounts.find((account) => account.id === id)
                                    .iban || id}
                                </span>
                                <span className={styles.copyIcon}>📋</span>
                              </div>
                            )}
                          </div>
                        ))}
                      </li>
                      {accountIds?.length > 0 && (
                        <ul className={styles.allInOneSheet}>
                          {showTransactionInstructions && (
                            <li
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}`
                                );
                                handlePositiveMessageOnClick(
                                  `All accounts URL copied to clipboard`
                                );
                              }}
                            >
                              <span>All in one sheet Transactions</span>
                              <span className={styles.copyIcon}>📋</span>
                            </li>
                          )}
                          {showBalanceInstructions && (
                            <li
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/Balances/${userId}`
                                );
                                handlePositiveMessageOnClick(
                                  `All accounts URL copied to clipboard`
                                );
                              }}
                            >
                              <span>All in one sheet Balances</span>
                              <span className={styles.copyIcon}>📋</span>
                            </li>
                          )}
                        </ul>
                      )}
                      <li>
                        In Excel, go to the "Data" tab and click on "Get Data".
                        <img
                          className={styles.imageInstructions}
                          src={click1}
                          alt="click1"
                        />
                      </li>
                      <li>
                        Choose "From Web".
                        <img
                          className={styles.imageInstructions}
                          src={click2}
                          alt="click2"
                        />
                      </li>
                      <li>
                        Paste the URL into the box and click "OK". To import the
                        data into Excel.
                        <img
                          className={styles.imageInstructions}
                          src={click3}
                          alt={"click3"}
                        />
                      </li>
                      <li>
                        Rename the query to the account number IBAN or any
                        meaningful name. And Then Click Into Table.
                        <img
                          className={styles.imageInstructions}
                          src={showBalanceInstructions ? clickBalance4 : click4}
                          alt="click4"
                        />
                      </li>
                      <li>
                        Remove The first row and Expand the second By clicking
                        on the 2 arrows.
                        <img
                          className={styles.imageInstructions}
                          src={showBalanceInstructions ? clickBalance5 : click5}
                          alt="click5"
                        />
                      </li>
                      <li>
                        Click on the expand To New Rows.
                        <img
                          className={styles.imageInstructions}
                          src={showBalanceInstructions ? clickBalance6 : click7}
                          alt="click7"
                        />
                      </li>
                      <li>
                        Click on the expand To New Col.
                        <img
                          className={styles.imageInstructions}
                          src={showBalanceInstructions ? clickBalance7 : click8}
                          alt="click8"
                        />
                      </li>
                      <li>
                        Click To Oncheck The _id and Click To Oncheck Use
                        original column name as prefix. And Click OK.
                        <img
                          className={styles.imageInstructions}
                          src={showBalanceInstructions ? clickBalance8 : click9}
                          alt="click9"
                        />
                      </li>
                      <li>
                        Click Close & Load To Finish.
                        <img
                          className={styles.imageInstructions}
                          src={
                            showBalanceInstructions ? clickBalance9 : click10
                          }
                          alt="click10"
                        />
                      </li>
                      <li>
                        You are all done. Now you can update with just Clicking
                        Refresh in the Data Tab.
                      </li>
                      <button
                        className={styles.excelInstructionsBtn}
                        onClick={() => setExpandUrls(!expandUrls)}
                      >
                        {expandUrls ? "Hide" : "Show"} Instructions
                      </button>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            {message && (
              <div className={styles.requisitionLimitReached}>
                <p>{message}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarSection;
