import React from "react";

import styles from "./CopyWrittingForTheApp.module.css";

const CopyWrittingForTheApp = () => {
  return (
    <div className={styles.infoContainer}>
      <h2 className={styles.infoHeader}>Make Managing Money Easy</h2>
      <p className={styles.infoParagraph}>
        Our tool integrates seamlessly with various platforms, including Excel,
        to help you:
      </p>
      <ul className={styles.infoOnOrededList}>
        <li>Track transactions effortlessly</li>
        <li>Manage multiple accounts with ease</li>
        <li>
          Allow others to view and refresh financial activity without needing
          access to payment methods
        </li>
        <li>
          Option for email updates twice a day
          {/*  or as per your preference */}
        </li>
      </ul>
      <p className={styles.FinnishingStatement}>
        Enhance productivity and streamline financial management for everyone.
      </p>
    </div>
  );
};

export default CopyWrittingForTheApp;
