import React from "react";

const Header = () => {
  return (
    <header className="header">
      <h1>
        <b>Bank Accounts in Excel</b>
      </h1>
      <h2 class="sales-pitch">
        works with Excel to make managing money easy. It lets you track
        transactions, manage multiple accounts, let others see and refresh
        financial activity without needing access to payment methods, and choose
        to get email updates twice a day or not while helping everyone work
        better and faster
      </h2>

      <div className="cta-header">
        <div className="signup-login-btn">
          <a href="/signup" className="cta-button">
            Get Started Now
          </a>

          <a href="/Login" className="cta-button">
            Login
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
